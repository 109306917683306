frappe.provide('frappe.phone_call');


class ViciCallHandler {
	constructor(to_numbers, frm) {
        let to_number;
        if (Array.isArray(to_numbers)) {
            this.to_number = to_numbers;
        } else {
            this.to_number = to_numbers.split('\n');
        }
		this.frm = frm
		this.make();
	}
    hide_hangup_button() {
		this.dialog.get_secondary_btn().addClass('hide');
	}
	make() {
        const me = this;
		this.dialog = new frappe.ui.Dialog({
			'title': __('Make a Call'),
			'minimizable': true,
			'fields': [{
				'fieldname': 'from_number',
				'label': 'From Number',
				'fieldtype': 'Data',
				'read_only': 0
			}, {
				'fieldname': 'to_number',
				'label': 'To Number',
                'options': this.to_number,
				'default': this.to_number[0],
				'fieldtype': 'Select',
				'read_only': 0
			}, {
				'fieldname': 'caller_id',
				'label': 'Caller ID',
				'fieldtype': 'Select',
				'reqd': 1
			}, {
				'fieldname': 'status',
				'label': 'Status',
				'fieldtype': 'Data',
				'read_only': 1
			}, /*{
				'fieldname': 'duration',
				'label': 'Duration',
				'fieldtype': 'Data',
                'default': "0",
                'read_only': 1
			},{
				'fieldtype': 'Button',
				'label': __('Call'),
				'primary': 1,
				'click': () => {
                    console.log(this.frm.doc.name)
					frappe.xcall('io2i_theme.telephony.vici_integration.make_a_call', {
                        'dt':this.frm.doctype,
                        'dn': this.frm.doc.name,
						'from_number': this.dialog.get_value('caller_id'),
						'to_number': this.dialog.get_value('to_number'),
					}).then(res => {
                        console.log(res)
                        if (res[0] == true) {
                        //this.dialog.set_secondary_action_label("Hang Up") 
                        this.dialog.set_title("in-progress");   
						this.dialog.set_value('response', res[1]);
                        //this.dialog.get_close_btn().show();
						this.call_id = res[1];
                        this.frm.doc.latest_cdr = res[1];
                        this.frm.refresh_field("latest_cdr")
                        
                        //window.onbeforeunload = function() {
                        //    return "you can not refresh the page";
                        //}
						//this.setup_call_status_updater();
                        } else {
                            this.dialog.set_value('response', res[1]);
                        }

					}).catch(e => {
						this.dialog.set_value('response', e);
					});
				}
			}, {
                'fieldtype': 'Button',
				'label': __('Hang Up'),
				'primary': 0,
				'click': () => {
                    console.log("hang Up2");
                    
                    this.get_primary_btn().addClass("btn-danger");
                    this.get_secondary_btn().addClass('hide');
                }   

            },
            {
				'label': 'Call Dispostion',
				'fieldtype': 'Section Break',
				'collapsible': 1
			}, {
				'fieldname': 'disp_code',
				'label': 'Disposition',
				'fieldtype': 'Data',
				'read_only': 0,
                'reqd':0
			},*/{
				'label': 'Response',
                'fieldname': 'response_section',
				'fieldtype': 'Section Break',
				'collapsible': 1
			}, {
				'fieldname': 'response',
				'label': 'Response',    
				'fieldtype': 'Data',
                'default': "1.7",
                'read_only': 1
			}],
            primary_action_label: 'Call',
            primary_action(values) {
                    frappe.xcall('io2i_theme.telephony.vici_integration.make_a_call', {
                        'dt':me.frm.doctype,
                        'dn': me.frm.doc.name,
						'from_number': values['caller_id'],
						'to_number': values['to_number'],
					}).then(res => {
                        console.log(res)
                        if (res[0] == true) {
                            //this.dialog.set_secondary_action_label("Hang Up") 
                            me.dialog.set_title("in-progress Duration: 0");
                           
                            var time = frappe.datetime.now_datetime()   
                            me.dialog.set_value('response', "CallSid-: " + res[1] + " Start Time-: " + time.toString() + " Duration -: 0")
                            var message = document.getElementsByClassName("form-message")[0].innerHTML
                            document.getElementsByClassName("form-message")[0].innerHTML = message.replace("Call Start Time:- 0" , "Call Start Time:- " + time.toString() )
                            me.frm.doc.call_start_time = time.toString()
                            me.frm.refresh_field("call_start_time")       
                            me.dialog.call_id = res[1];
                        
                            me.dialog.get_primary_btn().addClass("hide");
                            me.dialog.get_secondary_btn().removeClass("hide");                
                            me.dialog.get_secondary_btn().addClass("btn-danger");
                            me.dialog.get_close_btn().toggle(false)
                           
                            //me.dialog.set_df_property('disp_code', 'reqd', 1);
                            me.frm.doc.latest_cdr = res[1];
                            me.frm.set_value("m_disposition", "")
                            me.frm.set_df_property('m_disposition', 'reqd', 1);
                            me.frm.fields_dict["disposition_details_section"].collapse(0)
                            
                            me.frm.refresh_field("latest_cdr")
                            me.frm.refresh_field("m_disposition")
                            
                            
                            //window.onbeforeunload = function() {
                            //    return "you can not refresh the page";
                            //}
                            
                            me.setup_call_status_updater(me);
                        } else {
                            me.dialog.fields_dict['response_section'].collapse(0)                                                    
                            me.dialog.set_value('response', res[1]);
                            me.dialog.set_title("Error: " + res[1]);  
                            clearInterval(me.updater); 
                            //me.dialog.toggle_minimize()                       
              
                            me.dialog.get_close_btn().toggle(true)
                        }

					}).catch(e => {
						me.dialog.set_value('response', e);
                        
                        me.dialog.set_title("Error: " + this.dialog.get_value('response'));  
                        clearInterval(this.updater); 
                        this.dialog.toggle_minimize()                       
              
                        me.dialog.get_close_btn().toggle(true)
					});
            },
            secondary_action_label: 'Hang Up',
            secondary_action: (values) => {
                frappe.xcall('io2i_theme.telephony.vici_integration.handle_end_call', {
                    'dt':me.frm.doctype,
                    'dn': me.frm.doc.name,                   
                    'from_number': this.dialog.get_value('caller_id'),
                    'to_number': this.dialog.get_value('to_number')
                }).then(res => {
                    if (res[0] == "200") {
                        me.dialog.set_title("Call-ended " + this.dialog.get_value('response'));
                        var i = parseInt(me.dialog.get_value('response').split("-:")[3])  
                        clearInterval(this.updater); 
                        me.frm.doc.call_duration = i.toString()
                        me.frm.refresh_field("call_duration")                            
                        me.dialog.set_value('response', res[1]);
                        me.dialog.standard_actions.find(".btn-modal-secondary").addClass('hide')
                        me.dialog.standard_actions.find(".btn-modal-primary").removeClass('hide')
                        me.dialog.get_close_btn().toggle(true)
                        var message = document.getElementsByClassName("form-message")[0].innerHTML 
                        document.getElementsByClassName("form-message")[0].innerHTML = message.replace("Call Duration:- ","Call Duration:- " + i.toString() + " sec")
                        
                    } else {
                        me.dialog.set_value('response', res[0] + " " + res[1]);
                        me.dialog.set_title("Error: " + this.dialog.get_value('response'));  
                        clearInterval(this.updater); 
                        this.dialog.toggle_minimize()                       
              
                        me.dialog.get_close_btn().toggle(true)

                    }
                }).catch(e => {
                    me.dialog.set_value('response', e);
                   
                        me.dialog.set_title("Error: " + this.dialog.get_value('response'));  
                        clearInterval(this.updater); 
                        this.dialog.toggle_minimize()                       
              
                        me.dialog.get_close_btn().toggle(true)
                });
                
            }    
		});
        
			this.dialog.set_df_property('caller_id', 'options', frappe.boot.vici_enabled);
			this.dialog.set_value('caller_id', frappe.boot.vici_enabled);
         
			this.dialog.show();
            if (frappe.dynamic_link == "auto" && frappe.boot.vici_enabled){
                this.dialog.toggle_minimize()
                this.dialog.primary_action({'to_number':this.to_number[0], 'caller_id': frappe.boot.vici_enabled})
                frappe.dynamic_link ="manual"
            }
		/*frappe.xcall('io2i_theme.telephony.vici_integration.get_all_ext').then(numbers => {
            console.log(frappe.boot.vici_enabled)
			this.dialog.set_df_property('caller_id', 'options', numbers);
			this.dialog.set_value('caller_id', numbers[0]);
			this.dialog.show();
            //this.dialog.get_secondary_btn().addClass("hide");
		});*/
	}
	setup_call_status_updater(me) {
        console.log("1")
        
        //"<div>Call Duration:- 1  Wrap-Up Time:- 0 Total Time:- 0</div>"
                           
        //var i = 1
        //me.dialog.set_title("Duration:" + i.toString());
		if (!me.updater) {
			me.updater = setInterval(me.set_call_status.bind(me), 1000);
		}
	}
	set_call_status() {
       
        
        var i = parseInt(this.dialog.get_value('response').split("-:")[3]) + 1
        this.dialog.set_title("in-progress Duration:" + i.toString() ); 
        this.dialog.set_value('response', this.dialog.get_value('response').split("Duration")[0] + " Duration -: " + i.toString())
        
        
        		/*frappe.xcall('io2i_theme.telephony.vici_integration.get_call_status', {
			'call_id': this.call_id
		}).then(status => {
			this.dialog.set_value('status', status);
			this.set_indicator(status);
			if (['completed', 'failed', 'busy', 'no-answer'].includes(status)) {
				clearInterval(this.updater);
			}
		}).catch(() => {
			clearInterval(this.updater);
		});*/
	}

	set_indicator(status) {
		const indicator_class = this.get_status_indicator(status);
		this.dialog.header.find('.indicator').attr('class', `indicator ${indicator_class}`);
	}

	get_status_indicator(status) {
		const indicator_map = {
			'completed': 'red',
			'failed': 'red',
			'busy': 'yellow',
			'no-answer': 'yellow',
			'queued': 'yellow',
			'ringing': 'blue blink',
			'in-progress': 'blue blink'
		};

		const indicator_class = `indicator ${indicator_map[status] || 'blue blink'}`;
		return indicator_class;
	}

}

frappe.phone_call.handler = (to_number, frm) => new ViciCallHandler(to_number, frm);